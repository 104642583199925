<template>
  <!-- 账单中心 -->
  <div class="setMeal_box">
    <app-container :title="'账单中心'" :background="tool.getThemeList(theme)">
      <van-tabs
        v-model:active="active"
        color="#ffffff"
        title-inactive-color="#AAAAAA"
        :title-active-color="tool.getThemeList(theme)"
        @click="selectType"
      >
        <van-tab title="套餐记录">
          <!-- 标题栏下自定义内容 -->
          <div class="content_list">
            <div class="desc">
              <div
                class="desc_date"
                :class="{ active: activeIndex == index }"
                v-for="(item, index) in setMealDataList"
                :key="index"
                @click="selectMonth(index)"
              >
                {{ item.dt.slice(5, 7) }}月
              </div>
            </div>
            <div
              class="desc_con"
              :class="{ empty_list: setMealDataList_.length == 0 }"
            >
              <div v-if="setMealDataList_.length > 0">
                <div
                  class="desc_con_box"
                  v-for="(item, index) in setMealDataList_"
                  :key="index"
                >
                  <div class="left">
                    <div>
                      <img
                        src="../../assets/image/personal/order_icon.png"
                        alt=""
                      />
                    </div>
                    <div class="con">
                      <div class="con_desc">
                        <van-tag
                          :type="'success'"
                          v-if="item.category !== null"
                        >
                          {{
                            Number(item.category) == 1 ? "基础套餐" : "叠加套餐"
                          }}</van-tag
                        >
                        <span class="con_packageName">
                          {{ item.packageName }}
                        </span>
                      </div>
                      <div class="con_date">
                        {{ tool.getOrderType(item.orderType) }}
                      </div>
                      <div class="con_date">{{ item.createTime }}</div>
                    </div>
                  </div>
                  <div class="right">
										<div style="display:flex;">
											<div class="desc_reward">
                        <template v-if="amountShow()">
                            <span v-if="item.amount">{{item.amount}}￥</span>
                            <span v-else>
                              {{
                                item.orderAmount == 0
                                  ? item.orderAmount
                                  : "-" + item.orderAmount
                              }}￥
                            </span>
                        </template>                        
											</div>
											<template v-if="item.status == 6 || item.status == 8 || item.status == 9 || item.status == 10">
												<van-button class="applyRefund" size="mini" round  @click="operateHandle({id:item.id,status:item.status})">{{formatStatus(item.status)}}</van-button>
											</template>
											<template v-else>
												<van-tag class="applyRefund" type="success" round plain size="medium">{{statusText(item.status)}}</van-tag>
											</template>
										</div>
                    <template v-if="userInfo.labels.includes(1) && item.payCategory == 10"></template>
                    <!-- <div v-else-if="theme != 'YuYin'" class="desc_date">
                        <span v-if="theme == 'AnFang' && !userInfo.labels.includes(4)">
                            {{  item.beginTime  ? item.beginTime.slice(0, 10) + " 至 " : "" }}{{ item.endTime ? item.endTime.slice(0, 10) : "" }}
                        </span>
                    </div> -->
                  </div>
                </div>
              </div>
              <div v-else>
                暂无套餐记录~~~
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="余额记录">
          <!-- 标题栏下自定义内容 -->
          <div class="content_list">
            <div class="desc">
              <div
                class="desc_date"
                :class="{ active: activeIndex == index }"
                v-for="(item, index) in balanceDataList"
                :key="index"
                @click="selectMonth_(index)"
              >
                {{ item.name }}月
              </div>
            </div>
            <div
              class="desc_con"
              :class="{ empty_list: balanceDataList_.length == 0 }"
            >
              <div v-if="balanceDataList_.length > 0">
                <div
                  class="desc_con_box_"
                  v-for="(item, index) in balanceDataList_"
                  :key="index"
                >
                  <div class="left">
                    <img
                      src="../../assets/image/personal/order_icon.png"
                      alt=""
                    />
                  </div>
                  <div class="right">
                    <div class="con">
                      <div class="con_desc">
                        {{ tool.getFlowOrderType(item.type) }}
                      </div>
                      <div class="con_date">{{ item.createTime }}</div>
                    </div>

                    <div class="desc_reward">
                      {{
                        String(item.amount).indexOf("+") == -1 &&
                        String(item.amount).indexOf("-") != -1
                          ? String(item.amount)
                          : "+" + String(item.amount)
                      }}￥
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                暂无余额记录~~~
              </div>
            </div>
          </div>
        </van-tab>
				<van-tab title="发票管理">
					<div class="Invoice_list">
						<div
							ref="desc_con"
							class="desc_con"
							:class="{ empty_InvoiceList: InvoiceList.length == 0 }"
						>
							<van-list
								v-model:loading="loading"
								:finished="finished"
								:loading-text="'下滑加载更多,默认显示近三个月'"
								finished-text="没有更多了"
							>
								<div v-if="InvoiceList.length > 0">
									<div
										class="InvoiceList_box"
										v-for="(item, index) in InvoiceList"
										:key="index"
									>
										<div class="left">
											<div class="con">
												<div class="con_desc">
													<span class="con_invoiceType">
                            {{'套餐订购'}}
														<!-- {{ invoiceTypeHandle(item.invoiceType) }} -->
													</span>
													<span class="con_invoiceAmount">
														{{ item.invoiceTotalPriceTax }}元
													</span>
													<van-tag
														:type="'success'"
														v-if="item.invoiceMedium !== null"
													>
														<!-- {{
															Number(item.invoiceMedium) == 0 ? "电子发票" : "未知"
														}} -->
                            {{invoiceTypeCodeHandle(item.invoiceTypeCode)}}														
                            </van-tag
													>
												</div>
												<div class="InvoiceList_date">{{ item.createTime }}</div>
											</div>
										</div>
										<!-- <div class="center" v-if="item.billingStatus == 1 || item.billingStatus == 2 ">
											<img
												:src="invoiceLogo(item.billingStatus)"
												alt=""
											/>
										</div> -->
										<div class="invoice_arrow" @click="InvoiceHandle(1,item)">
											<div class="invoice_desc">
												<div class="desc_invoice">
													{{ invoiceStatusHandle(item.billingStatus) }}
												</div>
												<van-icon name="arrow" size="30" color="#D7D7D7"/>
											</div>
										</div>
									</div>
								</div>
								<div v-else style="text-align:center;">
									<img src="../../assets/image/personal/Invoice.png" alt="">
									<p>暂无开票记录</p>
								</div>
							</van-list>
						</div>
					</div>
					<div class="Invoice-button">
						<van-button class="Invoice" size="large" round  @click="InvoiceHandle(1)">去开票</van-button>
					</div>
				</van-tab>
      </van-tabs>
    </app-container>
  </div>
</template>

<script>
// 需要哪些模块按需引入
import { onMounted, onUnmounted, reactive, toRefs, getCurrentInstance, nextTick } from "vue";
import { useRoute } from "vue-router";
import { queryOrder, userMoneyHistory } from "_API_/api.services";
export default {
  setup() {
    // 个人觉着还是这样写舒服一点 类似于vue2中的data
    const state = reactive({
      theme: "", //主题名称
      active: 0,
      activeIndex: 0,
      setMealDataList: [], //套餐记录数据列表
      setMealDataList_: [], //套餐记录月度数据列表
      balanceDataList: [], //余额记录数据列表
      balanceDataList_: [], //余额记录月度数据列表
			loading:false,
			finished :false,
			page: 1, //页数
			limit: 10, //条数
			InvoiceList: [], //发票列表
      userInfo:{labels:[]},
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router : router, $http : http } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    if(route.query.isInvoice){
      nextTick(()=>{
        ctx.selectType(2)
      })
    }
    const methods = {
            // 套餐价格展示
            amountShow(){                
                if(state.theme == 'AnFang' && state.userInfo.labels.includes(4)){
                    return false
                }
                return true
            },
			// 去开票
			InvoiceHandle(type,{ id , status } = {}){
				switch (type) {
					case 0:
						router.push({
							name : `invoiced-detail`,
							query:{
								id,
								status
							}
						})
						break;
					case 1:
						router.push({
							name : "invoiced-list",
              query:{
								id,
							}
						})
						break;
					default:
						break;
				}
			},
			// 获取订单状态类型
			statusText(status){
				switch (status) {
          case 1:
            return "未支付";
          case 2:
            return "已完成";
          case 3:
            return "线上退款";
					case 4:
            return "线下退款";
					case 5:
            return "互电退款";
					case 7:
            return "退款失败";
          default:
            return "退款失败";
        }
			},
			// 获取按钮类型
			formatStatus(status){
				switch (status) {
					case 6:
						return '已退款'
					case 8:
						return '申请退款'
					case 9:
						return '已驳回'
					case 10:
						return '待审核'
					default:
						return '申请退款'
				}
			},
			// 批量退款相关操作
			operateHandle({id,status}){
        // 已退款  待审核不允许跳转看详情
        if ([6, 10].includes(Number(status))) return; 
				router.push({
					name: "batch-refund",
					query:{
						id,
						status,
					}
				})
			},
      //账单中心数据记录列表
      getList(type) {
        /**账单中心
         * @param {type} 1:套餐记录  2:余额记录
         */
        switch (type) {
          case "1":
            queryOrder(Object.assign(route.query, { type })).then(
              ({ data: res }) => {
                if (res.code == 0) {
                  state.setMealDataList = res.data; //获取所有数据源
                  state.setMealDataList_ =
                    res.data[res.data.length - 1].map.list || ""; //获取最近一个月的数据源
                  state.activeIndex = res.data.length - 1;
                }
              }
            );
            break;
          case "2":
            userMoneyHistory(Object.assign(route.query, { type })).then(
              ({ data: res }) => {
                if (res.code == 0) {
                  state.balanceDataList = res.data; //获取所有数据源
                  state.balanceDataList_ =
                    res.data[res.data.length - 1].values || ""; //获取最近一个月的数据源
                  state.activeIndex = res.data.length - 1;
                }
              }
            );
            break;
          default:
            break;
        }
      },
      //账单中心=>数据记录列表类型选择
      selectType(index) {
				// 移除监听
				window.removeEventListener("scroll", ctx.invoiceListScroll, true);
        state.active = index;
        switch (state.active) {
          case 0:
            ctx.getList("1");
            break;
          case 1:
            ctx.getList("2");
            break;
					case 2:
						// 监听发票列表滚动条
						window.addEventListener('scroll', ctx.invoiceListScroll, true);
						ctx.getInvoiceList();
						break;
          default:
            break;
        }
      },
      //套餐记录查询月份选择
      selectMonth(index) {
        state.activeIndex = index;
        state.setMealDataList_ = state.setMealDataList[index].map.list || "";
      },
      //余额记录查询月份选择
      selectMonth_(index) {
        state.activeIndex = index;
        state.balanceDataList_ = state.balanceDataList[index].values || "";
      },
			// 发票类型
			invoiceTypeHandle(val){
				switch (val) {
					case 1: return '订购套餐'
					case 2: return '共享池续费'
					default: return '订购套餐'
				}
			},
      // 发票种类
      invoiceTypeCodeHandle(invoiceTypeCode){
        switch (invoiceTypeCode) {
          case '004':
            return "增值税专用发票";
          case '005':
            return "机动车发票";
          case '006':
            return "二手车发票";
          case '007':
            return "增值税普通发票";
          case '025':
            return "增值税卷式发票";
          case '026':
            return "增值税电子发票";
          case '028':
            return "增值税电子专用发票";
          case '27':
            return "数字电子专票";
          case '26':
            return "数字电子普票";
          default:
            return "未知";
        }
      },
			// 发票状态
			invoiceStatusHandle(status){
				// switch (status) {
				// 	case 1:
				// 		return "已开票";
				// 	case 2:
				// 		return "待红冲";
				// 	case 3:
				// 		return "已驳回";
				// 	case 4:
				// 		return "待审核";
				// 	case 5:
				// 		return "已红冲";
				// 	case 6:
				// 		return "开票失败";
				// 	case 7:
				// 		return "已取消";
				// 	case 10:
				// 		return "未知";
				// 	default:
				// 		return "未知";
      	// }
        switch (Number(status)) {
					case 0:
						return "待开票";
					case 1:
						return "开票中";
					case 2:
						return "已开票";
					case 3:
						return "开票失败";
					default:
						return "未知";
      	}
			},
			// 发票管理列表
			getInvoiceList(){
				http.post(`/outputInvoice/page`,{
          pageNum: state.page,
          pageSize: state.limit,
          userType: 1,
          data:{
            iccid : store.state.user.userInfo.iccid
          }
				}).then(({data : res})=>{
					if(res.code == '200'){
						state.page++
						state.InvoiceList = state.InvoiceList.concat(res.page.list || [])
						if((res.page.list && !Boolean(res.page.list.length)) || (res.page.list && res.page.list.length < 7)){
							state.finished = true
						}
						return 
					}
					tool.toast({
            msg: res.msg,
            duration: 1500
          });
				})
			},
			/**
			 * @funcHandle invoiceListScroll-滚动条句柄
			 * @params invoiceListHeight-当前发票列表区域的总高度  clientHeight-当前发票列表区域在浏览器中的可视高度  scrollTop-当前浏览器滚动条到top位置的高度
			 *
			 */
			invoiceListScroll(){
				let invoiceListHeight= ctx.$refs['desc_con'].scrollHeight
				let totalHeight = ctx.$refs['desc_con'].clientHeight + ctx.$refs['desc_con'].scrollTop
				if(totalHeight == invoiceListHeight){
					state.page++
					ctx.getInvoiceList();
				}
			},
			//获取发票状态logo 1、已开票  2、待红冲 
			invoiceLogo(status){
				switch (status) {
					case 1:
						return require('../../assets/image/personal/Invoice_green.png')
					case 2:
						return require('../../assets/image/personal/Invoice_red.png')
					default:
						return require('../../assets/image/personal/Invoice_green.png')
				}
			}
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      state.userInfo = store.state.user.userInfo;
      if(!state.userInfo.labels){
        state.userInfo.labels = []
      }
      //获取套餐记录数据列表
      ctx.getList("1");
    });
		onUnmounted(()=>{
			// 移除监听
			window.removeEventListener("scroll", ctx.invoiceListScroll, true);
		})
    return { ...toRefs(state), ...methods, tool };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.setMeal_box {
  height: 100vh;
  .content_list,.Invoice_list {
    height: calc(80vh - 190px);
    overflow-y: auto;
    margin-top: 40px;
    .desc {
      width: 690px;
      height: 118px;
      background: #ffffff;
      border-radius: 20px 20px 0px 0px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-bottom: 1px solid #f2f2f2;
      .active {
        width: 136px;
        height: 58px;
        @include font_color("font_color1");
        background: #f1f2f3;
        border-radius: 29px;
        text-align: center;
        line-height: 58px;
      }
      .desc_date {
        color: #777777;
      }
    }
    .desc_con,
    .empty_list,
		.empty_InvoiceList {
      background: #ffffff;
      border-radius: 0px 0px 20px 20px;
      margin: 0 auto;
      padding: 16px 30px 0;
      .desc_con_box,.InvoiceList_box {
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
					align-items: center;
          div img {
            width: 48px;
            height: 48px;
          }
          .con {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 10px;
            .con_desc {
              font: {
                size: 30px;
                weight: 400;
              }
              color: #333333;
              .con_packageName,.con_invoiceType {
                white-space: nowrap;
                overflow-x: auto;
                display: inline-block;
                position: relative;
                top: 8px;
              }
							.con_packageName{
								width: 200px;
							}
							.con_invoiceType{
								width: 150px;
							}
							.con_invoiceAmount{
								padding-right: 10px;
							}
            }
            .con_date,.InvoiceList_date {
              font: {
                size: 22px;
                weight: 400;
              }
              color: #aaaaaa;
            }
						.InvoiceList_date{
							padding:10px 0px;
						}
          }
        }
				.center{
					width: 50px;
					height: 120px;
					img{
						position: relative;
						top: 12px;
					}
				}
        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .desc_reward {
            font-size: 36px;
            color: #fc992c;
          }
          .desc_date {
            font: {
              size: 22px;
              weight: 400;
            }
            color: #aaaaaa;
            white-space: nowrap;
          }
					.applyRefund {
						border: none;
						font-size: 16px;
						color: #fff;
						@include background_color("background_color1");
					}
        }
				.invoice_arrow{
					display: flex;
					align-items: center;
					.desc_invoice{
						font-size: 26px;
            color: #c9c4c4;
					}
					.invoice_desc{
						display: flex;
						align-items: center;
					}
				}
        .desc_reward,
        .desc_cutstom {
          font-size: 36px;
          color: #fc992c;
        }
        .desc_cutstom {
          color: #333333;
        }
      }
      .desc_con_box_ {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .left img {
          width: 48px;
          height: 48px;
        }
        .right {
          display: flex;
          justify-content: space-between;
          width: 564px;
          border-bottom-color: #f2f2f2;
          border-bottom-style: solid;
          border-bottom-width: 1px;
          .con {
            display: flex;
            flex-direction: column;
            .con_date {
              font: {
                size: 22px;
                weight: 400;
              }
              color: #aaaaaa;
              padding: 10px 0px;
            }
          }
          .desc_reward {
            font-size: 36px;
            color: #fc992c;
          }
        }
      }
			.InvoiceList_box{
				border-bottom-color: aliceblue;
				border-bottom-width: 1px;
				border-bottom-style: solid;
			}
    }
    .desc_con {
      width: 630px;
      height: calc(60vh - 118px);
      overflow-y: auto;
    }
    .empty_list,
		.empty_InvoiceList {
      width: 610px;
      height: 200px;
      padding: 40px;
      text-align: center;
      line-height: 200px;
      font-size: 26px;
      color: #b2b2b2;
    }
		.empty_InvoiceList{
      height: 500px;
		}
  }
	.Invoice_list{
		height: calc(80vh - 290px);
	}
}
.Invoice-button{
	width: 690px;
	margin: 10px auto;
}
.Invoice{
	color: #fff;
	width: 100%;
	@include background_color("background_color1");
}
</style>
